import React from "react";
import './assets/css/style.css'
import Router from "./config/router";
function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
