import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "../page/Home";
import Terms from "../page/Terms";
import Privacy from "../page/Privacy";
import ComingSoon from "../page/ComingSoon";


export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<ComingSoon />} />{" "}
        {/* <Route index path="/" element={<Home />} />{" "}
        <Route index path="/terms" element={<Terms />} />{" "}
        <Route index path="/privacy" element={<Privacy />} />{" "} */}
      </Routes>
    </BrowserRouter>
  );
}
