import React from 'react';
import './ComingSoon.css';

const ComingSoonSec = () => {
    return (
        <div className="coming-soon-container">
            <h1 className="title">iWantIt</h1>
            <p className="subtitle">Coming Soon...</p>
        </div>
    );
}

export default ComingSoonSec;
