import React from 'react';

import ComingSoonSec from '../components/ComingSoon/ComingSoon.js';

const ComingSoon = () => {
  return (
    <div className='comingsoon-page'>
      <ComingSoonSec />
    </div>
  );
}

export default ComingSoon;